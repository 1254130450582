import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                // primary: '#0081BA',
                // primary: '#0070A1',
                primary: '#1476bf',
                buttonclr: '#8ec33c',
                success: '#8ec33c',
                error: '#ff3737',
                text: '#025c83',
                //button: '#0070A1',
                button: '#52BE80',
                excel: '#217346',
                pdf: '#b70f0a',
                csv: '#f0ad4e',


                insitu: '#3CD3DD',
                lab: '#D43BC6',
            },
            dark: {},
        },
    },
    icons: {
        iconfont: 'mdi',
    },

});
