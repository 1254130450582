<template>
  <v-app>
    <!-- app header -->
    <app-header></app-header>

    <!-- left panel system admin-->
    <left-panel v-if="$route.meta.showLeftPanel"></left-panel>

    <!-- content -->
    <v-main class="bg">
      <router-view :key="$route.path"></router-view>
    </v-main>

    <!-- Dialog Popup Message Success/Error -->
    <v-dialog
    v-model="dialogPopupSuccessError"
    width="500"
    >
      <v-card>

          <v-card-title
          class="text-h5 white--text" 
          :style="popupStatus == 'success'? 'background:#09b049;' : 'background:#ff3737;'">
            {{popupTitle}}
          </v-card-title>

          <v-card-text style="padding:20px 24px;font-weight:bold;">
            {{popupMessage}}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="button"
            dark
            @click="dialogPopupSuccessError = false"
            >
                OK
            </v-btn>
          </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- app footer -->
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import { bus } from '@/main';

import AppHeader from "@/components/Header";
import AppFooter from "@/components/AppFooter";
import LeftPanelSysAdmin from "@/components/SysAdmin/LeftPanelSysAdmin";
export default {
  name: "App",
  components: {
    "app-header": AppHeader,
    "app-footer": AppFooter,
    "left-panel": LeftPanelSysAdmin,
  },

  data: () => ({

    dialogPopupSuccessError: false,

    popupStatus: "",
    popupTitle: "",
    popupMessage: ""

  }),

  methods: {},

  created() {
    bus.$on("popupMessage", (data) => {
      
      this.popupStatus = data.status;
      this.popupTitle = data.title;
      this.popupMessage = data.message;

      this.dialogPopupSuccessError = true;

    });
  },

  watch: {},
};
</script>

<style lang="scss">
@import "~scss/main";

.bg{
  background-image: url("~@/assets/background.jpg") !important;
  background-size: cover !important;
  background-position: 50% 50%;
  overflow-y: hidden;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
