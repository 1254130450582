export default {
    data() {
        return {
            // globalUrl: "https://apis.spatialworks.com.my/",
            globalUrl: "https://miqims.jmg.gov.my/",
        }
    },

    mounted() {

    },

    methods: {

        goToPage(url) {

            this.$router.push({ path: url }).catch(() => { });

        },

        reloadPage() {

            window.location.reload();

        },

        // To use, put this line of code => @keypress="isNumber($event)" in element
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        useConvert12Hours(time) {

            if (time != undefined || time != null || time.trim() != "") {
                var H = +time.substr(0, 2);
                var h = H % 12 || 12;
                var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;

                return time;
            }
            else {
                return null
            }

        },

        useConvertDisplayDate(date) {

            if (date != undefined || date != null || date.trim() != "") {
                var [yyyy, mm, dd] = date.split("-");
                date = dd + "/" + mm + "/" + yyyy;

                return date;
            }
            else {
                return null
            }

        },

        useConvertDatabaseDate(date) {

            if (date != undefined || date != null || date.trim() != "") {
                var [dd, mm, yyyy] = date.split("/");
                date = yyyy + "-" + mm + "-" + dd;

                return date;
            }
            else {
                return null
            }

        },

        useConvertDisplayDatetime(datetime) {

            // console.log(datetime);

            if (datetime != undefined) {

                if (datetime != null || datetime?.trim() != "") {

                    datetime.includes("T") ? datetime = datetime.replace("T", " ") : datetime;

                    let [date, time] = datetime.split(" ");

                    // Change Date
                    var [yyyy, mm, dd] = date.split("-");
                    date = dd + "/" + mm + "/" + yyyy;

                    // Change Time
                    var H = +time.substr(0, 2);
                    var h = H % 12 || 12;
                    var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                    time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;

                    datetime = date + ", " + time

                    return datetime;

                }
                else {
                    return null
                }

            }
            else {
                return null
            }



        },

        useConvertDatabaseDatetime(datetime) {

            console.log(datetime)

            if (datetime != undefined) {

                if (datetime != null || datetime.trim() != "") {

                    if (datetime.includes(",")) {
                        datetime = datetime.replace(",", "")
                    }


                    let unitTime = null
                    if (datetime.includes("AM")) {
                        datetime = datetime.split(" AM")[0]
                        unitTime = "AM"
                    }
                    else if (datetime.includes("PM")) {
                        datetime = datetime.split(" PM")[0]
                        unitTime = "PM"
                    }

                    let [date, time] = datetime.split(" ");

                    let [dd, mm, yy] = date.split("/");

                    // Change Date format from display format to DB format
                    date = yy + "-" + mm + "-" + dd;


                    // Change Time format from 12 to 24 hours
                    time = time + " " + unitTime
                    console.log(time);
                    const [time12, modifier] = time.split(' ');
                    let [hours, minutes, seconds] = time12.split(':');
                    if (hours === '12') { hours = '00'; }
                    if (modifier === 'PM') { hours = parseInt(hours, 10) + 12; }

                    console.log();

                    if (typeof hours == "number") {
                        hours = hours < 10 ? "0" + hours : hours
                    }
                    time = hours + ":" + minutes + ":" + seconds

                    datetime = date + "T" + time;

                    return datetime;

                }
                else {
                    return null
                }

            }
            else {
                return null
            }



        }

    },

    computed: {

        levelAdmin() {
            return this.$store.getters.user.role === "admin" ? true : false
        },

        levelOperation() {
            return this.$store.getters.user.role === "operation" ? true : false
        },

        levelGeneral() {
            return this.$store.getters.user.role === "user" ? true : false
        },

        levelQAQC() {
            return this.$store.getters.user.role === "qaqc" ? true : false
        },

        // levelUser() {
        //     return this.$store.getters.userRole === 'user' ? true : false
        // },

    },
}