<template>

  <v-footer
  id="app-footer"
  padless
  height="100%"
  v-if="$route.meta.showFooter && $vuetify.breakpoint.lgAndUp"
  class="app-footer-color"
  >
  
    <v-row class="justify-center align-center text-center" style="width: 80px !important" >

      <v-col cols="2" sm="4" class="white--text pr-10" :style="$vuetify.breakpoint.mdAndDown? 'font-size: 12px;text-align:right;' : 'text-align:right;'">
        <a href="https://www.jmg.gov.my/en/penafian-dan-notis-hak-cipta" target="_blank" class="white--text footerlink" style="text-decoration: none;">Disclaimer and Copyright Notice </a> <br>
        <a href="https://www.jmg.gov.my/en/dasar-privasi-dan-keselamatan" target="_blank" class="white--text footerlink" style="text-decoration: none;">Safety and Privacy Policy </a><br>
        <a href="/D17_DOKUMEN_MANUAL_PENGGUNA_SISTEM.pdf" target="_blank" class="white--text footerlink" style="text-decoration: none;">User Manual  </a>
      </v-col>

      <v-col cols="2" sm="4" class="white--text px-0" :style="$vuetify.breakpoint.mdAndDown? 'font-size: 12px;text-align:center;' : 'text-align:center;'">
        For general enquiries, please email at: <br>
        <v-icon color="white">mdi-email</v-icon> <a href="" class="white--text footerlink">helpdesk@jmg.gov.my</a>
      </v-col>

      <v-col cols="2" sm="4" class="white--text pl-10" :style="$vuetify.breakpoint.mdAndDown? 'font-size: 12px;text-align:left;' : 'text-align:left;'">
        Browser Compatibility
        <br>
        <v-icon color="white" class="ml-5">mdi-google-chrome</v-icon>
        <v-icon color="white" class="mx-5">mdi-firefox</v-icon>
        <v-icon color="white">mdi-microsoft-edge</v-icon>
      </v-col>

    </v-row>

  </v-footer>

</template>

<script>
  export default {
    data: () => ({
      links: [
        {
          name: "Disclaimer and Copyright Notice",
          link: "https://www.jmg.gov.my/en/penafian-dan-notis-hak-cipta"
        },
        {
          name: "Safety and Privacy Policy",
          link: "https://www.jmg.gov.my/en/dasar-privasi-dan-keselamatan"
        },
        {
          name: "User Manual",
          link: "/D17_DOKUMEN_MANUAL_PENGGUNA_SISTEM.pdf"
        },
      ]
    }),
  }
</script>

<style lang="scss" scoped>

.app-footer-color {
    // background-image: linear-gradient(to left,#0072bb, #02B3C3);
    // background-color: #225b6c !important;
    // background-image: linear-gradient(to right, #225b6c, #327083) !important;

    // background-color: #0081BA !important;
    background-image: linear-gradient(to left,#04b194, #0081BA) !important; 
    position: absolute;
    bottom: 0;
    width:100%;
  }

.logo{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  // left: 20px;
  right: 800px;
  // text-align: center;
  top:0px;
}

.footerlink:hover {
  font-size: 18px;
}

</style>