import { OSM, ImageWMS, Vector as VectorSource, ImageArcGISRest } from 'ol/source';
import {
  Image as ImageLayer,
  Tile as TileLayer, 
  Vector as VectorLayer,
  Group as GroupLayer,
} from 'ol/layer';

export default {
  data() {
      return {

        // GENERAL
        baseMapLayer: null,
          
        // TILE LAYER
        googleStreetMap: null,
        googleSateliteMap: null,
        streetMap: null,
        topographicMap: null,
        terrainMap: null,

      }
  },    

  mounted(){

    this.setBasemap()
      
  },

  methods: {

    setBasemap(){

      
      // Layer Esri Satelite Map
      this.esriSateliteMap = new TileLayer({
          title: "Esri Satellite Map",
          source: new OSM({
            url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
      }),
      // this.googleSateliteMap = new TileLayer({
      //     title: "OSM",
      //     source: new OSM({
      //     url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
      //     crossOrigin: "anonymous",
      //     }),
      //     visible: true,
      //     type: "base",
      // });
      // Layer Google Street Map
      this.googleStreetMap = new TileLayer({
          title: "Google Street Map",
          source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
          // "url": 'http://mt1.googleapis.com/vt?x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
      });
      // Layer Street Map
      this.streetMap = new TileLayer({
          title: "Street Map",
          source: new OSM({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
      });
      // Layer Topographic Map
      this.topographicMap = new TileLayer({
          title: "Topographic Map",
          source: new OSM({
          url: "http://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.png",
          crossOrigin: "anonymous",
          }),
          visible: true,
          type: "base",
      });
      // Layer Terrain Map
      this.terrainMap = new TileLayer({
          title: "Terrain Map",
          source: new OSM({
          url: "http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z} ",
          crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
      });


      // Grouping All Base Layer Map
      let groupBase = [
          this.esriSateliteMap,
          // this.googleSateliteMap,
          this.googleStreetMap,
          this.streetMap,
          this.topographicMap,
          this.terrainMap
      ]
      this.baseMapLayer = new GroupLayer({
          layers: groupBase
      });

    }
      
  },
  
  computed: {


  },
}