<template>
  <div v-if="$route.meta.showHeader">

    <v-app-bar id="app-header" class="class-appbar" clipped-right clipped-left app elevation="1">

      <!-- =============== LEFT SIDE =============== -->

      <!-- Logo -->
      <v-app-bar-nav-icon class="pa-3 class-logo">
        <v-img src="../assets/img/logo_rasmi_jmg.png" height="50" contain @click="goToPage('/Mapviewer')">
        </v-img>
      </v-app-bar-nav-icon>

      <!-- Title (Desktop) -->
      <v-toolbar-title class="class-title" v-if="$vuetify.breakpoint.mdAndUp">
        <h4>MiQIMS Mines & Quarry Integrated Monitoring System</h4>
      </v-toolbar-title>

      <!-- Title (Mobile) -->
      <v-toolbar-title class="class-title-mobile" v-else>
        <h3>MiQIMS</h3>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- =============== RIGHT SIDE =============== -->

      <div class="class-icongroup pa-2">

        <!-- // User Manual (Desktop & Mobile) -->
        <v-menu origin="center center" transition="scale-transition" offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                  href="https://miqims.jmg.gov.my/uploads/apks/miqims_apps_v1.0.apk" target="_blank">
                  <v-icon color="white" role="img">mdi-robot</v-icon>
                </v-btn>
              </template>
              <span>Download APK</span>
            </v-tooltip>
          </template>
        </v-menu>

        <!-- // User Manual (Desktop & Mobile) -->
        <v-menu origin="center center" transition="scale-transition" offset-y>

          <template v-slot:activator="{ on, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...on }">
                  <v-icon color="white" role="img">mdi-book-open-variant</v-icon>
                </v-btn>
              </template>
              <span>User Manual</span>
            </v-tooltip>
          </template>

          <v-list class="list-inChild py-0" flat>
            <v-list-item-group color="primary">
              <v-subheader class="subHeaderList">User Manual</v-subheader>
              <v-list-item v-for="(item, index) in userManual" :key="index" @click="goToPage(item.url)"
                class="cust-menu-report-items">
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>

        </v-menu>

        <!-- Request Api Button (Desktop) -->
        <v-menu origin="center center" transition="scale-transition" offset-y v-if="$vuetify.breakpoint.mdAndUp">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                  @click="goToPage('/requestAPI/requestApiList')">
                  <v-icon color="white" role="img">mdi-access-point-network</v-icon>
                </v-btn>
              </template>
              <span>Request API</span>
            </v-tooltip>
          </template>
        </v-menu>

        <!-- Alert Button (Desktop) -->
        <v-menu origin="center" transition="scale-transition" offset-y content-class="mt-4 cust-menu-header"
          class="cust-menu-header" style="z-index: 50;" v-if="$vuetify.breakpoint.mdAndUp">

          <template v-slot:activator="{ on, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...on }">
                  <v-icon color="white" role="img">mdi-bell-alert</v-icon>
                </v-btn>
              </template>
              <span>Alert</span>
            </v-tooltip>
          </template>

          <v-list class="list-inChild py-0" flat>
            <v-list-item-group color="primary">
              <v-subheader class="subHeaderList">Alert</v-subheader>
              <v-list-item v-for="(item, index) in alertItems" :key="index" :to="item.url" class="cust-menu-report-items">
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>

        </v-menu>

        <!-- Report Button (Desktop) -->
        <v-menu origin="center center" transition="scale-transition" offset-y content-class="mt-4 cust-menu-header"
          class="cust-menu-header" style="z-index: 50;" v-if="$vuetify.breakpoint.mdAndUp">

          <template v-slot:activator="{ on, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...on }">
                  <v-icon color="white" role="img">mdi-file-document</v-icon>
                </v-btn>
              </template>
              <span>Report</span>
            </v-tooltip>
          </template>

          <v-list class="list-inChild py-0">
            <v-subheader v-show="wqReportItems.length > 0" class="subHeaderList">Water Quality Report</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, index) in wqReportItems" :key="index" :to="item.url"
                class="cust-menu-report-items">
                <!-- {{item.title}} -->
                <v-list-item-title v-html="item.title"></v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-list class="list-inChild py-0">
            <v-subheader v-show="operationReportItems.length > 0" class="subHeaderList">Operational</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, index) in operationReportItems" :key="index" :to="item.url"
                class="cust-menu-report-items">
                <v-list-item-title v-if="item.title != 'QAQC 2sec Level'" v-html="item.title"></v-list-item-title>
                <v-list-item-title v-if="item.title == 'QAQC 2sec Level'">
                  {{ item.title.split("sec")[0] }}<sup>nd</sup> <span class="ml-0">{{ item.title.split("sec")[1] }}</span>
                </v-list-item-title>

              </v-list-item>
            </v-list-item-group>
          </v-list>

          <!--<v-list class="list-inChild">
              <v-subheader class="subHeaderList">CMS Reporting</v-subheader>
              <v-list-item-group
                color="primary"
              >
              <v-list-item
                v-for="(item, index) in cmsReportItems"
                :key="index"
                :to="item.url"
              >
                <v-list-item-title disabled v-text="item.title" ></v-list-item-title>

              </v-list-item>
              </v-list-item-group>
            </v-list>-->

        </v-menu>

        <!-- Button System Admin (Desktop) -->
        <v-menu origin="center center" transition="scale-transition" offset-y content-class="mt-4"
          v-if="$vuetify.breakpoint.lgAndUp">

          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>

              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...menu }" @click="updateRole();">
                  <v-icon color="white" role="img">mdi-account-circle</v-icon>
                </v-btn>
              </template>
              <span>Profile</span>
            </v-tooltip>
          </template>

          <v-list class="list-inChild py-0">
            <v-subheader class="subHeaderList">Profile</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item class="cust-menu-report-items" @click=" goToPage('/userProfile/userProfile')">
                <v-list-item-title>User Profile</v-list-item-title>
              </v-list-item>

              <v-list-item class="cust-menu-report-items" v-show="adminCanSee == true" @click="goToPage(superAd)">
                <v-list-item-title>System Admin</v-list-item-title>
              </v-list-item>

            </v-list-item-group>
          </v-list>

        </v-menu>

        <!-- Menu (Mobile) -->
        <v-menu origin="center center" transition="scale-transition" offset-y v-if="$vuetify.breakpoint.mdAndDown">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                  @click="mobileNavBar = !mobileNavBar">
                  <v-icon color="white" role="img">mdi-menu</v-icon>
                </v-btn>
              </template>
              <span>Menu</span>
            </v-tooltip>
          </template>
        </v-menu>

        <!-- Button Logout (Desktop & Mobile) -->
        <v-menu origin="center center" transition="scale-transition" offset-y>

          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon color="white" role="img" @click="signOut">mdi-logout</v-icon>
                </v-btn>
              </template>
              <span>Log Out</span>
            </v-tooltip>
          </template>

        </v-menu>





      </div>


      <!-- </v-app-bar-nav-icon> -->

    </v-app-bar>

    <!-- //MOBILE BOTTOM NAV-->
    <div>
      <v-bottom-sheet v-model="mobileNavBar">
        <v-sheet class="bottom-sheet-mobile-background">
          <v-expansion-panels>
            <v-expansion-panel v-for="(item, i) in mobileitems" :key="i">
              <v-expansion-panel-header class="d-flex expansion-header" style="background-color: #0081BA;">
                <v-card-text class="white--text pa-0 ma-0 font-weight-bold">
                  <v-icon class="mr-2 white--text">{{ item.icon }}</v-icon> {{ item.title }}
                </v-card-text>
              </v-expansion-panel-header>

              <v-expansion-panel-content>

                <v-list-item dense link v-for="(list, j) in item.subheader" :key="j" :to="list.url"
                  @click="mobileNavBar = false">
                  <v-card-subtitle class="font-weight-bold">
                    <v-icon class="mr-4">{{ list.icon }}</v-icon> {{ list.title }}
                  </v-card-subtitle>

                </v-list-item>

              </v-expansion-panel-content>

            </v-expansion-panel>
          </v-expansion-panels>
        </v-sheet>
      </v-bottom-sheet>
    </div>


  </div>
</template>

<script>
import { bus } from '@/main';

export default {

  data: () => ({

    // foradmin: false,
    adminCanSee: false,
    superAd: null,
    thisuser: null,
    mobileNavBar: false,
    drawer: false,
    group: null,
    userManual: [
      // { title:'Exceedance' , url: "/Alert/Exceedance"},
      { title: 'System', url: "/usermanual_system" },
      // { title:'Mobile Application' , url: "/usermanual_mobile"},
    ],
    alertItems: [
      // { title:'Exceedance' , url: "/Alert/Exceedance"},
      { title: 'Data Transmission Status', url: "/Alert/Transmission" },
      { title: 'Exceedance by Parameter', url: "/Alert/ExceedanceByParameter" },
    ],
    wqReportItems: [],
    allwqReportItems: [
      { title: 'Water Quality Data', url: "/Report/WaterQualityData" },
      { title: 'Daily Report', url: "/Report/DailyReport" },
      { title: 'Monthly Report', url: "/Report/MonthlyReport" },
      { title: 'Yearly Report', url: "/Report/YearlyReport" },
      { title: 'Query Options', url: "/Report/QueryOptions" },
      { title: 'Raw Data', url: "/Report/RawData" },
      { title: 'Baseline Study Report', url: "/Report/BaselineStudyReport" },
      { title: 'Investigative Study Report', url: "/Report/InvestigateStudyReport" }
    ],
    operationReportItems: [],
    alloperationReportItems: [
      { title: 'Maintenance', url: "/Operational/Maintenance" },
      { title: 'Calibration', url: "/Operational/Calibration" },
      { title: 'QAQC 2sec Level', url: "/Operational/QAQCSecLevel" },
      { title: 'Validation', url: "/Operational/Validation" },
      // { title:'Investigate Study Data Entry', url: "/Operational/InvestigateStudyDataEntry"}
      { title: 'Investigative Study', url: "/Operational/InvestigateStudyDataEntry" }
    ],
    allcmsReportItems: [
      { title: 'Reporting', url: "/CMSReporting/Reporting" },
    ],
    userItems: [
      { title: 'User Profile', url: "/userProfile/userProfile" },
      { title: 'System Admin', url: "/systemAdmin/userManagement/userList" },
    ],

    mobileitems: [
      {
        title: 'Profile',
        icon: "mdi-account-circle",
        subheader: [
          { title: 'User Profile', url: '/userProfile/userProfile', icon: 'mdi-badge-account-horizontal-outline' },
          { title: 'System Admin', url: '/systemAdmin/userManagement/userList', icon: 'mdi-account-tie' }
        ]
      },
      {
        title: 'Water Quality Report',
        icon: "mdi-file-document",
        subheader: [
          { title: 'Daily Report', url: '/Report/DailyReport', icon: 'mdi-file-document-outline' },
          { title: 'Monthly Report', url: '/Report/MonthlyReport', icon: 'mdi-file-document-outline' },
          { title: 'Yearly Report', url: '/Report/YearlyReport', icon: 'mdi-file-document-outline' },
          { title: 'Query Options', url: '/Report/QueryOptions', icon: 'mdi-database-settings-outline' },
          { title: 'Raw Data', url: '/Report/RawData', icon: 'mdi-file-sign' },
          { title: 'Baseline Study Report', url: "/Report/BaselineStudyReport" },
          { title: 'Investigative Study Report', url: '/Report/InvestigateStudyReport', icon: 'mdi-file-search-outline' }
        ]
      },
      {
        title: 'Operational',
        icon: "mdi-bell-alert",
        subheader: [
          { title: 'Maintenance', url: '/Operational/Maintenance', icon: 'mdi-progress-wrench' },
          { title: 'Calibration', url: '/Operational/Calibration', icon: 'mdi-tune' },
          { title: 'QAQC 2nd Level', url: '/Operational/QAQCSecLevel', icon: 'mdi-nfc-search-variant' },
          { title: 'Validation', url: '/Operational/Validation', icon: 'mdi-file-sign' },
          // {title:'Investigate Study Data Entry', url: '/Operational/InvestigateStudyDataEntry', icon:'mdi-file-search-outline'}
          { title: 'Investigative Study', url: '/Operational/InvestigateStudyDataEntry', icon: 'mdi-file-search-outline' }
        ]
      },
      {
        title: 'Alert',
        icon: "mdi-bell-alert",
        subheader: [
          { title: 'Exceedance', url: '/Alert/Exceedance', icon: 'mdi-water-percent-alert' },
          { title: 'Data Transmission Status', url: '/Alert/Transmission', icon: 'mdi-list-status' },
          { title: 'Exceedance by Parameter', url: '/Alert/ExceedanceByParameter', icon: 'mdi-beaker-alert-outline' }
        ]
      },
      {
        title: 'Request API',
        icon: "mdi-access-point-network",
        subheader: [
          { title: 'Request API List', url: '/requestAPI/requestApiList', icon: 'mdi-format-list-bulleted' },
          { title: 'Request API Application Form', url: '/requestAPI/requestApiForm', icon: 'mdi-file-document-multiple-outline' },
          { title: 'Telemetri Station List', url: '/requestAPI/stationList', icon: 'mdi-format-list-bulleted-triangle' }
        ]
      },


    ],

    logOut: {
      title: 'Log Out'
    },
  }),

  mounted() {

    // console.log(this.$store.getters.user.idUserAccess);

    let thisuser = this.$store.getters.user.idUserAccess;

    if (thisuser == 1) {
      this.superAd = '/systemAdmin/userManagement/userList'
    } else if (thisuser == 5) {
      this.superAd = '/systemAdmin/stationManagement/stationList'
    }

    console.log(thisuser)

    if (thisuser === 1 || thisuser === 5) {
      this.adminCanSee = true;

    }

    else {
      this.adminCanSee = false;
    }

    // this.userView(thisuser)



    let menuSetting = localStorage.clickButtonAMIS != undefined ? JSON.parse(localStorage.clickButtonAMIS) : { a: true, m: false, is: false };
    // console.log(menuSetting);

    this.setListMenuReport(menuSetting.a, menuSetting.m, menuSetting.is, menuSetting.bs)

  },

  methods: {

    updateRole() {

      let id = this.$store.getters.user.idUserAccess;

      id === 1 || id === 5 ? this.adminCanSee = true : this.adminCanSee = false

    },

    signOut() {
      this.$store.dispatch('signOut')
    },

    openPdf() {
      // window.open("file/manual.pdf","_blank");
      window.open("/Sample.pdf", "_blank");
    },

    openMobileNav() {
      bus.$emit('openNavBar', 'mobile');
    },

    setListMenuReport(C, M, IS, BS) {

      // this.wqReportItems
      // this.operationReportItems

      let visibleReportWQR = []
      let visibleReportOperational = []

      let thisuser = this.$store.getters.user.idUserAccess;

      if (C == true && M == false) {

        this.wqReportItems = [];
        this.operationReportItems = [];

        visibleReportWQR = [
          "Daily Report",
          "Monthly Report",
          "Yearly Report",
          "Query Options",
          "Raw Data",
        ]

        if (thisuser !== 3) {

          if (thisuser == 2) {

            visibleReportOperational = [
              "Maintenance",
              "Calibration",
            ]

          } else if (thisuser == 4) {

            visibleReportOperational = [
              "QAQC 2sec Level",
              "Validation",
            ]

          } else {

            visibleReportOperational = [
              "Maintenance",
              "Calibration",
              "QAQC 2sec Level",
              "Validation",
            ]

          }

        }

      }
      else if (C == false && M == true) {

        this.wqReportItems = [];
        this.operationReportItems = [];

        let thisuser = this.$store.getters.user.idUserAccess;

        visibleReportWQR = [
          "Water Quality Data",
          "Query Options",
          "Raw Data",
        ]

        if (thisuser !== 2) {

          if (thisuser !== 3) {
            visibleReportOperational = [
              "Validation",
            ]
          }
        }


      }
      else if (C == true && M == true) {

        this.wqReportItems = [];
        this.operationReportItems = [];
        let thisuser = this.$store.getters.user.idUserAccess;

        visibleReportWQR = [
          "Water Quality Data",
          "Daily Report",
          "Monthly Report",
          "Yearly Report",
          "Query Options",
          "Raw Data",
        ]

        if (thisuser !== 3) {

          if (thisuser == 2) {

            visibleReportOperational = [
              "Maintenance",
              "Calibration",
            ]

          } else if (thisuser == 4) {

            visibleReportOperational = [
              "QAQC 2sec Level",
              "Validation",
            ]

          } else {

            visibleReportOperational = [
              "Maintenance",
              "Calibration",
              "QAQC 2sec Level",
              "Validation",
            ]

          }

        }

      }
      else if (IS == true) {

        this.wqReportItems = [];
        this.operationReportItems = [];

        visibleReportWQR = [
          "Raw Data",
          "Investigative Study Report",
        ]

        visibleReportOperational = [
          // "Investigate Study Data Entry",
          "Investigative Study",
        ]

      }
      else if (BS == true) {

        this.wqReportItems = [];
        this.operationReportItems = [];

        visibleReportWQR = [
          "Baseline Study Report",
        ]

      }
      else {

        this.wqReportItems = [];
        this.operationReportItems = [];

      }

      // Push list Report - Water Quality Report
      for (let i in this.allwqReportItems) {
        for (let x in visibleReportWQR) {
          if (this.allwqReportItems[i].title == visibleReportWQR[x]) {
            this.wqReportItems.push({
              title: this.allwqReportItems[i].title,
              url: this.allwqReportItems[i].url
            })
          }
        }
      }
      this.wqReportItems = this.wqReportItems.filter((v, i, a) => a.findIndex(v2 => (v2.title === v.title)) === i)

      // Push list Report - Operational
      for (let i in this.alloperationReportItems) {
        for (let x in visibleReportOperational) {
          if (this.alloperationReportItems[i].title == visibleReportOperational[x]) {
            this.operationReportItems.push({
              title: this.alloperationReportItems[i].title,
              url: this.alloperationReportItems[i].url
            })
          }
        }
      }
      this.operationReportItems = this.operationReportItems.filter((v, i, a) => a.findIndex(v2 => (v2.title === v.title)) === i)


    }
  },

  created() {
    bus.$on('trigger - header', (data) => {

      if (data.focus == "clickButtonAMIS") {
        this.setListMenuReport(data.a, data.m, data.is, data.bs)
      }

    });
    // bus.$on('admincanuse', (data) => {
    //   if(data === 1){
    //     this.foradmin = true;
    //   }

    //   else{
    //     this.foradmin = false;
    //   }
    // });
  }

};
</script>

<style lang="scss">
@import '~scss/main';

.class-logo {
  position: absolute;
  left: 30px;
}

.class-title {
  position: absolute;
  left: 100px;
  color: white;
}

.class-title-mobile {
  position: absolute;
  left: 80px;
  color: white;
}

.class-appbar {
  // background-image: linear-gradient(to right, #343333, #5a5a5a) !important;
  //background-image: linear-gradient(to left,#02B3C3, #0081BA) !important; 

  background-image: url("~@/assets/header.jpg") !important;
  background-size: cover !important;
  background-position: 50% 60%;
  overflow-y: hidden;
  height: 100%;
  position: relative;
}

.class-icongroup {
  color: white;
}

.navmobile:hover {
  font-size: 15px;
  cursor: pointer;
}

.bottom-sheet-mobile-background {
  // background-color:whitesmoke !important;
  background-image: url("~@/assets/sheet2.jpg") !important;
  background-size: cover !important;
  background-position: 50% 100% !important;
  overflow-y: hidden;
}

.cust-menu-report-items:hover {
  background: #12acdf;
  color: white !important;
  cursor: pointer;
}

.cust-menu-header {
  z-index: 50 !important;
}

.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active.mt-4 {
  z-index: 50 !important;
}
</style>


