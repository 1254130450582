import axios from 'axios'
import router from '@/router'
import { bus } from '@/main';
import appMixins from '@/mixins/globalMixins'

export default {
    state: {
        user: '',
        auth: {
            token: 'null',
        },
        loading: false,
        error: null,
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        clearError(state) {
            state.error = null
        },
    },
    actions: {
        setUser({ commit }, payload) {
            commit('setUser', payload)
        },
        signUserIn({ commit, state }, payload) {
            commit('setLoading', true)
            axios
                .post(appMixins.data().globalUrl + `mqm2/users/login`, payload)
                .then((response) => {
                    commit('setLoading', false)
                    const newUser = payload
                    commit('setUser', response.data)

                    if (!state.user || !state.user.token || state.user.token.split('.').length != 3 || JSON.parse(atob(state.user.token.split('.')[1])).nameid == null)   //kasi kuat sikit ceking
                    {
                        console.log('no token found');
                        return false;
                    }
                    else {
                        localStorage.setItem('user', JSON.stringify(response.data))
                        router.push('/Mapviewer')
                    }

                })
                .catch((error) => {
                    commit('setLoading', false);
                    commit('setError', error.response.data);
                    //alert('wrong username/password');

                    bus.$emit('triggerSnackBar');

                    if (error.response.status === 401) {
                        this.signOut();
                    }
                })
        },
        signOut({ commit }) {
            localStorage.clear()
            commit('setUser', null)
            commit('setError', null)
            router.push('/').catch(() => { });
        },
        checkUserLocalStorage({ commit, getters }) {
            const user = JSON.parse(localStorage.getItem('user'))
            if (user) {
                commit('setUser', user)
            }
        },
        clearError({ commit }) {
            commit('clearError')
        },
        refreshToken({ state }) {
            //alert('token '+state.user.token)
            return new Promise((resolve, reject) => {

                if (!state.user || !state.user.token) {
                    console.log('no token found');
                    return false;
                }
                else {
                    axios
                        .post(appMixins.data().globalUrl + `mqm2/users/renewjwt`, null, {  //renewjwt xperlu body, only header
                            headers: {
                                Authorization: 'Bearer ' + state.user.token,
                            },
                        })
                        .then((response) => {
                            // update token on user state
                            // update token on localstorage
                            console.log('token renew at ' + new Date());
                            state.user.token = response.data.newjwt;  //get new jwt n put it in state
                            window._mycurrentjwt = state.user.token;  //_mycurrentjwt just for debug
                            resolve(response)
                            if (!state.user || !state.user.token) {
                                router.push('/').catch(() => { })  //kene login
                                console.log("no token");

                            }
                        })
                        .catch((error) => {
                            // set user on state to null
                            // clear local storage
                            // goto login page
                            router.push('/').catch(() => { })  //kene login
                            // reject(error)
                        })
                }

            })
        },
    },
    getters: {
        user(state) {
            return state.user
        },
        token(state) {
            return state.user.token
        },
        error(state) {
            return state.error
        },
        loading(state) {
            return state.loading
        },
        userRole(state) {
            return state.user.role
        },

    },
}
